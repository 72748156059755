import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';

import { ResetCore } from '../../core/+state/core.actions';
import { TokenService } from '../../core/services/token.service';
import { LoginResponse } from '../../models';
import { AuthService, CloudMessagingService } from '../../services';
import { UserMeStoreActions } from '../user-me-store';

import * as fromActions from './actions';
import { LogoutBroadcastService } from '../../services/logout-broadcast.service';

@Injectable()
export class AuthStoreEffects {
  private logoutBroadcast = inject(LogoutBroadcastService);

  constructor(
    private authService: AuthService,
    private actions$: Actions,
    private tokenService: TokenService,
    private router: Router,
    private cloudMessagingService: CloudMessagingService,
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loginRequest),
      switchMap(({ loginRequest }) =>
        this.authService.login({ ...loginRequest, v: 2 }).pipe(
          mergeMap((response: LoginResponse) => {
            const { token } = response;
            this.tokenService.store(token);

            return [
              fromActions.loginSuccess({
                token,
              }),
              UserMeStoreActions.loadRequest({ redirect: '/' }),
            ];
          }),
          catchError((error) => of(fromActions.loginFailure(error))),
        ),
      ),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.logoutRequest),
      switchMap(({ loginRedirect }) => {
        const resultActions = [
          new ResetCore(),
          UserMeStoreActions.resetState(),
          UserMeStoreActions.cleanup(),
          fromActions.logoutSuccess({ loginRedirect }),
        ];

        const token = this.tokenService.get();

        if (!token) {
          return resultActions;
        }

        return this.cloudMessagingService.destroy().pipe(
          switchMap(() =>
            this.authService.logout(token).pipe(
              mergeMap(() => {
                this.tokenService.delete();
                return resultActions;
              }),
              catchError((error) => throwError(error)),
            ),
          ),
          catchError((error) => throwError(error)),
        );
      }),
      catchError((error) => {
        localStorage.setItem('logoutError', JSON.stringify(error));
        return of(null);
      }),
    ),
  );

  afterLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.logoutSuccess),
      switchMap(({ loginRedirect }) => {
        const queryParams = { redirect: loginRedirect };

        this.router.navigate(['/login'], {
          queryParams,
        });

        this.logoutBroadcast.publish({
          type: 'logout-in-all-tabs',
        });

        return of(fromActions.resetState());
      }),
    ),
  );
}
