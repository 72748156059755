import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getAuthVerificationMethods = (state: State) =>
  state.authVerificationMethods;

export const getStep = (state: State) => state.step;

export const getHasSucceded = (state: State) => state.hasSucceded;

export const getPinStepMessage = (state: State) => state.pinStepMessage;

export const selectState = createFeatureSelector<State>('auth');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectAuthVerificationMethods = createSelector(
  selectState,
  getAuthVerificationMethods,
);

export const selectStep = createSelector(selectState, getStep);

export const selectHasSucceded = createSelector(selectState, getHasSucceded);

export const selectPinStepMessage = createSelector(
  selectState,
  getPinStepMessage,
);
