import { AuthVerificationMethod, LoginStep } from '../../models';

export interface State {
  isLoading?: boolean;
  error?: any;
  hasSucceded: boolean;
  authVerificationMethods: AuthVerificationMethod[];
  step: LoginStep;
  pinStepMessage: string;
}

export const initialState: State = {
  isLoading: false,
  hasSucceded: false,
  error: null,
  authVerificationMethods: [],
  step: 'credentials_step',
  pinStepMessage: null,
};
