import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';
import { LoginStep } from '../../models';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loginRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loginSuccess, (state) => ({
    ...state,
    isLoading: false,
    hasSucceded: true,
    error: null,
  })),
  on(fromActions.loginFailure, (state, { error }) => {
    if (error?.available_auth_verification_methods?.length) {
      return {
        ...state,
        isLoading: false,
        error,
        authVerificationMethods: error?.available_auth_verification_methods,
        step: 'auth_verification_method_step' as LoginStep,
      };
    }

    if (error?.next_action === 'pin') {
      return {
        ...state,
        isLoading: false,
        error,
        step: 'pin_step' as LoginStep,
        pinStepMessage: error.message,
      };
    }

    return {
      ...state,
      isLoading: false,
      error,
    };
  }),

  on(fromActions.resetState, () => fromState.initialState),
);

export function authReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
